import React from 'react'
import PrivateLayout from '../layouts/private'
import styled from 'styled-components'
import Div100vh from 'react-div-100vh'
import { Previous } from 'grommet-icons'
import { Link } from 'gatsby'
import { colors } from '../theme'

const Back = styled.div`
  position: fixed;
  top: 10px;
  left: 10px;
  height: 40px;
  background-color: ${colors.primary};
  padding: 5px 5px 0 0;
  border-radius: 6px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const Wrapper = styled(Div100vh)`
  width: 100%;
  iframe {
    height: 100%;
    width: 100%;
    border: none;
  }
`

const KohLantaPage = (props) => {
  return <PrivateLayout>
    <Wrapper>
      <Back>
        <Link to='/'><Previous color='#fff' size='30px' /></Link>
      </Back>
      <iframe src="https://tf1.fr/tf1/direct" />
    </Wrapper>
  </PrivateLayout>
}

KohLantaPage.propTypes = {

}

KohLantaPage.defaultProps = {

}

export default KohLantaPage
